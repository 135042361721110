import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import SplitType from 'split-type';
import { offset } from '@popperjs/core';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
CustomEase.create('slideEase', 'M0,0,C0.92,0.012,0.108,1,1,1');

/* split type wrapping lines */
function wrapLine(lines) {
	lines.forEach((line, index) => {
		let origLine = line.innerHTML;
		let wrappedLine = '<div class="lineInner" data-order="' + index + '">' + origLine + '</div>';
		line.innerHTML = wrappedLine;
	});
}
/* split type removing overflow from lines */
function removeOverflow(target) {
	/* target needs to be an object node node */
	target.parentElement.classList.add('overflowNormal');
}

gsap.registerEffect({
	name: 'counter',
	extendTimeline: true,
	defaults: {
		end: 0,
		duration: 0.5,
		ease: 'power4.out',
		increment: 1,
	},
	effect: (targets, config) => {
		let tl = gsap.timeline();
		let num = targets[0].innerText.replace(/\,/g, '');
		targets[0].innerText = num;

		tl.to(
			targets,
			{
				duration: config.duration,
				innerText: config.end,
				//snap:{innerText:config.increment},
				modifiers: {
					innerText: function (innerText) {
						return gsap.utils
							.snap(config.increment, innerText)
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
					},
				},
				ease: config.ease,
			},
			0
		);

		return tl;
	},
});

export default function animation() {
	/* kontaktujte nas banner */

	const bannerContact = document.querySelector('.bannerContact');
	if (bannerContact) {
		const mediaQuery = window.matchMedia('(min-width: 768px)');
		if (mediaQuery.matches) {
			let heading = bannerContact.querySelector(' h2');
			const split = new SplitType(heading, { types: 'lines' });
			wrapLine(split.lines);
			let innerLines = bannerContact.querySelectorAll('.lineInner');
			const tl = new gsap.timeline({
				scrollTrigger: {
					trigger: bannerContact.querySelector('h2'),
					scrub: false,
					markers: false,
				},
			});
			tl.from(innerLines, { duration: 1.2, y: '100%', stagger: 0.25, ease: 'power2.out' })
				.call(function () {
					innerLines.forEach((element) => {
						removeOverflow(element);
					});
				})
				.from(bannerContact.querySelector('p'), { opacity: 0, duration: 0.8 }, 0.4)
				.from(bannerContact.querySelector('a.btn-primary'), { duration: 0.8, opacity: 0 }, 0.6);
		}
	}

	const pageHome = document.querySelector('.page-homepage');
	if (pageHome) {
		/* section.top */

		const mediaQuery = window.matchMedia('(min-width: 768px)');
		let heading;
		if (mediaQuery.matches) {
			heading = document.querySelector('section.top h2.d-none.d-md-block');
		} else {
			heading = document.querySelector('section.top h2.d-block.d-md-none');
		}
		let subHeading = document.querySelector('section.top .subtextPolygon small');

		const split = new SplitType(heading, { types: 'lines' });
		wrapLine(split.lines);
		let innerLines = heading.querySelectorAll('.lineInner');

		const tl = new gsap.timeline({ delay: 0.1 });
		tl.set('section.top',{autoAlpha:1})
    .from(innerLines, { duration: 1.2, y: '100%', stagger: 0.25, ease: 'power2.out' })
			.call(function () {
				innerLines.forEach((element) => {
					removeOverflow(element);
				});
			})

			.from('section.top .img', { duration: 1, scale: 0.95, opacity: 0 }, 0.6)
			.from('section.top .img img', { duration: 1, scale: 1.2 }, 0.6)
			.from('section.top h3.subtextPolygon small', { duration: 0.8, opacity: 0, y: -20 }, 0.4)
			.to('section.top .smooth-scroll svg circle', { duration: 1.2, strokeDashoffset: 0, ease: 'slideEase' }, 1.5)
			.from('section.top .smooth-scroll svg path', { duration: 0.8, opacity: 0 }, '-=0.7');

		const scrollBtn = document.querySelector('section.top .smooth-scroll svg');
		scrollBtn.addEventListener('mouseenter', function () {
			gsap.to('section.top .smooth-scroll svg circle', { duration: 0.6, strokeDashoffset: -433, overwrite: true });
		});
		scrollBtn.addEventListener('mouseleave', function () {
			gsap.to('section.top .smooth-scroll svg circle', { duration: 0.6, strokeDashoffset: 0, overwrite: true });
		});

		/* section.claim */

		const claim = document.querySelector('section.claim p');
		const claimTl = new gsap.timeline({
			scrollTrigger: {
				trigger: 'section.claim',
				scrub: false,
			},
		});
		claimTl.from(claim, { duration: 1, autoAlpha: 0, y: 40 });

		/* section.about */
		gsap.to('section.about > .wrap .img svg', { duration: 10, rotation: 360, repeat: -1, ease: 'none' });
		const aboutTl = new gsap.timeline({
			scrollTrigger: { trigger: 'section.about', scrub: false, markers: false },
		});
		aboutTl.from('section.about > .wrap', { duration: 0.8, scale: 0.9 });
	}

	const archive = document.querySelector('body.archive');
	if (archive) {
		/* section.top */
		let heading = document.querySelector('section.top h1 span:not(.badge)');
		let subHeading = document.querySelector('section.top h1 small');
		let badge = document.querySelector('section.top h1 span.badge');

		const tl = new gsap.timeline({ delay: 0.1 });
		tl.set('section.top',{autoAlpha:1}).from(heading, { autoAlpha: 0, y: 40, duration: 1.2 })
			.from('section.top picture.img', { duration: 1, scale: 0.95, opacity: 0 }, 0.6)
			.from('section.top .img img', { duration: 1, scale: 1.2 }, 0.6)
			.from(subHeading, { duration: 0.8, opacity: 0, y: -20 }, 0.4)
			.from(badge, { duration: 0.8, opacity: 0, ease: 'slideEase' }, 0.6);
	}

	const single = document.querySelector('body.page-produkt');
	if (single) {
		/* section.top */

		let heading = document.querySelector('section.top h1');
		const tl = new gsap.timeline({ delay: 0.1 });

		tl.set('section.top',{autoAlpha:1}).from(heading, { opacity: 0, ease: 'power2.out', duration: 1.2, y: 40 })
			.from('section.top small.cat span:not(line)', { opacity: 0, duration: 0.6 }, 0.4)
			.from('section.top small.cat a', { opacity: 0, duration: 0.6 }, 0.4)
			.to('section.top small.cat span.line', { webkitClipPath: 'inset(0% 0% 0% 0%)', duration: 0.5 }, '-=0.7')
			.from('section.top .img', { scale: 0.9, y: 20, duration: 0.6, opacity: 0 }, 0.5);

		const mediaQuery = window.matchMedia('(min-width: 768px)');
		let scrubDist;
		if (mediaQuery.matches) {
			scrubDist = -90;
		} else {
			scrubDist = -40;
		}

		const scrubTl = new gsap.timeline({
			scrollTrigger: {
				trigger: 'section.top .img',
				scrub: true,
				start: 'top center',
			},
		});
		scrubTl.to('section.top .img', { y: scrubDist, duration: 1 });
	}

	const about = document.querySelector('body.page-about');
	if (about) {
		let heading = document.querySelector('section.top h1');

		const split = new SplitType(heading, { types: 'lines' });
		wrapLine(split.lines);
		let innerLines = heading.querySelectorAll('.lineInner');

		const tl = new gsap.timeline({ delay: 0.1 });
		tl.set('section.top',{autoAlpha:1}).from(innerLines, { duration: 1.2, y: '100%', stagger: 0.25, ease: 'power2.out' })
			.call(function () {
				innerLines.forEach((element) => {
					removeOverflow(element);
				});
			})
			.from('section.top .img', { duration: 1, scale: 0.95, opacity: 0 }, 0.6)
			.from('section.top .img img', { duration: 1, scale: 1.2 }, 0.6);

		gsap.to('section.certificate  #badgeGarance', { duration: 10, rotation: 360, repeat: -1, ease: 'none' });

		const fst = document.querySelector('section.usp .wrap:first-of-type > strong');
		const fstNum = fst.dataset.counter;
		const scnd = document.querySelector('section.usp .wrap:nth-child(2) > strong');
		const scndNum = scnd.dataset.counter;
		const trd = document.querySelector('section.usp .wrap:last-of-type > strong');
		const trdNum = trd.dataset.counter;

		const tlCounter = new gsap.timeline({
			scrollTrigger: {
				trigger: 'section.usp',
			},
		});
		tlCounter.counter(fst, { end: fstNum, duration: 3 }).counter(scnd, { end: scndNum, duration: 3 }, 0).counter(trd, { end: trdNum, duration: 3 }, 0);
	}

	const contact = document.querySelector('body.page-contact');
	if (contact) {
		const links = document.querySelectorAll('.middle .bigLink');
		links.forEach((element) => {
			const split = new SplitType(element, { types: 'lines' });
			wrapLine(split.lines);
		});

		const tl = new gsap.timeline({ delay: 0.2 });
		tl.set('section.top',{autoAlpha:1})
      .from('.middle .wrap:first-of-type small.label', { duration: 0.4, opacity: 0 })
			.from('.middle .wrap:first-of-type .lineInner', { duration: 0.6, y: '100%' }, '-=0.6')
			.from('.middle .wrap:last-of-type small.label', { duration: 0.4, opacity: 0 }, 0.4)
			.from('.middle .wrap:last-of-type .lineInner', { duration: 0.6, y: '100%' }, '-=0.6')
			.from('.middle .btn-primary', { duration: 0.4, opacity: 0 }, '-=0.2')
			.from('section.top .bottom', { duration: 0.4, opacity: 0 })
			.call(function () {
				removeOverflow(document.querySelector('.middle .wrap:last-of-type .lineInner'));
			})
			.call(function () {
				removeOverflow(document.querySelector('.middle .wrap:first-of-type .lineInner'));
			});
	}
}
