/**
 * SMOOTHSCROLLING - v0.3
 *
 * Example usage
 * <a href="#" class="smooth-scroll"></a>
 */

function smoothScroll() {
	let elements = document.querySelectorAll('.smooth-scroll');

	elements.forEach(function (element) {
		element.addEventListener('click', function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				let target = this.hash;
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				let view = document.querySelector(target);

				if (target.length) {
					view.scrollIntoView({
						behavior: 'smooth',
					});
				}
			}
		});
	});
}
export { smoothScroll };
