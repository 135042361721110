/**
 * Custom scripts
 * ==============
 */
// javascript is enabled
//import mainThreeScene from './classes/mainThreeScene';
import { createPopper } from '@popperjs/core';
import { Collapse, Popover, Dropdown } from 'bootstrap';
import smoothscroll from 'smoothscroll-polyfill';
import { smoothScroll } from './plugins/smoothscroll';
import animation from './classes/gsap';
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';
import marquee from 'vanilla-marquee';
import { gsap } from 'gsap';
import SimpleBar from 'simplebar';


const body = document.getElementsByTagName('body')[0];
body.classList.remove('no-js');

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

let showMenu = (menuAction) => {
	const menu = document.getElementById('fullscreenMenu');

	if (menuAction == 'open') {
		body.classList.add('openedMenu');
		menu.setAttribute('aria-hidden', 'false');
		const tl = new gsap.timeline();

		tl.to('#fullscreenMenu', { autoAlpha: 1, duration: 0.8 })
			.from('#fullscreenMenu .menuItems span, #fullscreenMenu .menuItems .main li a', { y: 20, autoAlpha: 0, stagger: 0.05, duration: 0.4 }, 0.2)
			.from('#fullscreenMenu .menuItems li.scnd a', { y: 20, autoAlpha: 0, stagger: 0.05, duration: 0.4 }, 0.25);
	}
	if (menuAction == 'close') {
		body.classList.remove('openedMenu');
		menu.setAttribute('aria-hidden', 'true');
		gsap.to('#fullscreenMenu', { autoAlpha: 0, duration: 0.2 });
	}
};

window.addEventListener('load', () => {
	animation();
});

document.addEventListener('DOMContentLoaded', () => {
  /* const popup = document.querySelector('.popup');
  const popupClose = document.querySelector('.popup .popup__close');
  const popupOverlay = document.querySelector('.popup .popup__overlay');
  
   if(getCookie('amper2023')){
    popup.classList.add('closed');
  }
  
  popupClose.addEventListener('click', (e) => {
    e.preventDefault();
    popup.classList.add('closed');
    document.cookie = "amper2023=true; max-age=31536000; path=/";  
  })

  popupOverlay.addEventListener('click', (e) => {
    e.preventDefault();
    popup.classList.add('closed');
    document.cookie = "amper2023=true; max-age=31536000; path=/";
  })  */


  
  

	const menu = document.getElementById('menuBtn');
	const menuClose = document.getElementsByClassName('closeBtn')[0];

	if (menu) {
		menu.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('open');
		});
	}
	if (menuClose) {
		menuClose.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('close');
		});
	}

	const formPage = document.querySelector('body.page-mam-zajem');
	if (formPage) {
		const ulDropdown = document.querySelector('#myDropdown ul');
		jQuery(document).on('nfFormReady', function () {
			/* naplnit dropdown */
			const options = document.querySelectorAll('select option');
			const btn = document.querySelector('#myDropdown button');
			btn.innerHTML = options[0].innerHTML;
			btn.setAttribute('value', options[0].value);

			options.forEach((element) => {
				const text = element.innerHTML;
				const value = element.value;
				let newLi = document.createElement('li');
				newLi.classList.add('dropdown-item');
				newLi.setAttribute('value', value);
				newLi.innerHTML = text;
				ulDropdown.appendChild(newLi);
			});
			new SimpleBar(document.querySelector('#myDropdown ul'));

			/* menit na click */
			const li = document.querySelectorAll('#myDropdown li ');

			li.forEach((element) => {
				element.addEventListener('click', () => {
					const inner = element.innerHTML;
					const liValue = element.getAttribute('value');
					btn.innerHTML = inner;

					btn.setAttribute('value', liValue);
				});
			});
		});

		/* menit obsah nf */
		if (typeof Marionette !== 'undefined') {
			var mySubmitController = Marionette.Object.extend({
				fieldType: 'listselect',

				initialize: function () {
					// init listener
					this.listenTo(nfRadio.channel(this.fieldType), 'render:view', this.initAction);
					this.listenTo(Backbone.Radio.channel('forms'), 'submit:response', this.actionSubmit);
					Backbone.Radio.channel(this.fieldType).reply('get:submitData', this.getSubmitData);
					this.listenTo(Backbone.Radio.channel(this.fieldType), 'change:modelValue', this.valueChanged);
				},
				getSubmitData: function (fieldData) {
					const btn = document.querySelector('#myDropdown button');
					const btnValue = btn.getAttribute('value');
					fieldData.value = btnValue;
				},
				actionSubmit: function () {
					document.querySelector('#myDropdown').style.display = 'none';
				},
			});
			new mySubmitController();
		}
	}
	/**
	 * enable smoothscroll plugin
	 */
	smoothScroll();

	const dalsiSlider = document.querySelector('.dalsiSlider');
  
  
	if (dalsiSlider) {
    const countSlide = dalsiSlider.dataset.count ;
    let currentSlide = dalsiSlider.dataset.current ;
    
    if(countSlide <= 3){
      currentSlide=0;
    }
   
		var slider = tns({
			container: '.dalsiSlider',
			items: 1,
			slideBy: '1',
			autoplay: false,
			gutter: 20,
			loop: true,
			nav: false,
			controlsText: ['Předchozí', 'Další'],
			controlsPosition: 'top',
			controlsContainer: '.custom-controls',
			edgePadding: 18,
      startIndex:currentSlide,
			responsive: {
				768: {
					items: 2,
				},
				1200: {
					items: 3,
					edgePadding: 0,
				},
			},
		});
	}

	const gallerySlider = document.querySelector('.gallerySlider');
	if (gallerySlider) {
		var slider = tns({
			container: gallerySlider,
			items: 1,
			slideBy: 1,
			gutter: 0,
			autoplay: false,
			loop: true,
			nav: false,
			gutter: 20,
			controlsText: ['Předchozí', 'Další'],
			controlsPosition: 'top',
			edgePadding: 18,
			responsive: {
				768: {
					items: 2,
					edgePadding: 0,
				},
			},
		});
	}

	if (document.getElementById('marquee')) {
		const marqueeBrands = new marquee(document.getElementById('marquee'), {
			direction: 'left',
			duplicated: true,
			startVisible: true,
			speed: 100,
			recalcResize: false,
			gap: 80,
		});
	}



  /* new katalogy embed popup */
  let soubory = document.querySelectorAll('.soubor');
  
  soubory.forEach((soubor) => {
    soubor.addEventListener('click', function(e){
      if(e.target.tagName == 'A'){
        return;
      }
      let popup = soubor.querySelector('.soubor_popup');
      /* get embed url */
      let embed = soubor.querySelector('embed');
      let embedUrl = embed.dataset.src;
      /* set embed url to src attribute */
      embed.setAttribute('src', embedUrl);
 
      /* open popup */
      document.querySelector('body').classList.add('popup_open');
      popup.classList.add('open');
      /* close popup */
      if(e.target.classList.value == 'popup_close'){
        document.querySelector('body').classList.remove('popup_open');
        popup.classList.remove('open');
      }
    });
  })
});


